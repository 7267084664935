import initialState from "./state";

const modules = {
  setCurrentAcademy: (state, payload) => {
    state.currentAcademy = payload;
  },

  setAcademies: (state, payload) => {
    state.academies = payload;
  },
  RESET: (state) => {
    Object.assign(state, initialState);
  },
};

export default modules;
