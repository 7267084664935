import router from "../router";

const authPlugin = (store) => {
  store.subscribeAction({
    after: (action, state) => {
      switch (action.type) {
        // handling signup flow:
        // signup + setuser + getUser + goToCreateAcademy
        case "auth/signup": {
          if (state.auth.currentAuth != undefined) {
            let newUser = {
              email: action.payload.email,
              name: action.payload.name,
            };
            store.dispatch("user/signupSet", {
              id: state.auth.currentAuth.uid,
              user: newUser,
            });
          }
          break;
        }
        case "user/signupSet": {
          if (state.auth.currentAuth != undefined) {
            store.dispatch("user/signupGet", state.auth.currentAuth.uid);
          }
          break;
        }
        case "user/signupGet": {
          if (state.user.currentUser != undefined) {
            store.dispatch("loading", false);
            router.push("/academy/create");
          }
          break;
        }

        // handling login flow
        // login + user/signupGet + academy/get + user/list + billing/list
        case "auth/login": {
          store.dispatch("user/loginGet", state.auth.currentAuth.uid);
          break;
        }
        case "user/loginGet": {
          if (state.user.currentUser.academies != undefined) {
            store.dispatch("academy/get", state.user.currentUser.academies[0]);
          } else {
            store.dispatch("loading", false);
            router.push("/academy/create");
          }
          break;
        }
        /* case "user/loginGet": {
          router.push("/");
          store.dispatch("loading", false);
          break;
        } */
        case "academy/get": {
          if (state.academy.currentAcademy != undefined) {
            store.dispatch("user/loginList", state.academy.currentAcademy.id);
            store.dispatch(
              "billing/loginList",
              state.academy.currentAcademy.id
            );
          }
          break;
        }
        case "user/loginList": {
          if (state.academy.currentAcademy != undefined) {
            store.dispatch("loading", false);
            if (router.currentRoute.fullPath != "/") {
              if (
                router.currentRoute.fullPath == "/login" ||
                router.currentRoute.fullPath == "/signup"
              ) {
                router.push("/");
              } else {
                router.back();
              }
            }
          }
          break;
        }
        case "billing/loginList": {
          store.dispatch("loading", false);
          if (router.currentRoute.fullPath != "/") {
            if (
              (router.currentRoute.fullPath == "/login") |
              (router.currentRoute.fullPath == "/signup")
            ) {
              router.push("/");
            } else {
              router.back();
            }
          }
          break;
        }
      }
    },
  });
};
export default authPlugin;
