<script>
import SignupTemplate from "../templates/SignupTemplate.vue";

export default {
  components: { SignupTemplate },
  name: "SignupPage",
  data: () => ({
    valid: false,
    showPassword: false,
  }),
  methods: {
    goToLogIn() {
      this.$router.push("login");
    },
    signup(form) {
      this.$store.dispatch("auth/signup", form);
    },
  },
};
</script>

<template>
  <signup-template @SignUp="signup" @LogIn="goToLogIn" />
</template>
