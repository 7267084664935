import firebase from "@/firebase";

const billingService = {
  async create(academyId, billing) {
    let newBilling = { ...billing };
    const response = await firebase
      .firestore()
      .collection("academies")
      .doc(academyId)
      .collection("billing")
      .add(newBilling);

    if (response) {
      return billing;
    } else {
      return {};
    }
  },
  async update(academyId, billingId, billing) {
    const response = await firebase
      .firestore()
      .collection("academies")
      .doc(academyId)
      .collection("billing")
      .doc(billingId)
      .update({ ...billing });
    if (response) {
      return true;
    } else {
      return false;
    }
  },
  async delete(academyId, billingId) {
    const response = await firebase
      .firestore()
      .collection("academies")
      .doc(academyId)
      .collection("billing")
      .doc(billingId)
      .delete();
    if (response) {
      return true;
    } else {
      return false;
    }
  },
  async list(academyId) {
    const response = await firebase
      .firestore()
      .collection("academies")
      .doc(academyId)
      .collection("billing")
      .get();

    if (response.docs.length > 0) {
      let billingList = [];
      response.forEach((doc) => {
        let billing = doc.data();
        billing.id = doc.id;
        billingList.push(billing);
      });
      return billingList;
    } else {
      return [];
    }
  },
};

export default billingService;
