import router from "../router";
import generateRandomString from "@/helpers/generateRandomString";

const userPlugin = (store) => {
  store.subscribeAction({
    after: (action, state) => {
      switch (action.type) {
        // handle member creation
        case "user/create": {
          let billing = action.payload.billing;
          billing.memberId = action.payload.id;

          if (
            billing.date != "" ||
            billing.reference != "" ||
            billing.price != "" ||
            billing.method != ""
          ) {
            store.dispatch("billing/userCreate", billing);
          }
          store.dispatch("user/createList", state.academy.currentAcademy.id);
          break;
        }
        case "user/createList": {
          store.dispatch("loading", false);
          router.go(-1);
          break;
        }
        case "user/updateList": {
          store.dispatch("loading", false);
          router.go(-1);
          break;
        }

        // member update flow
        case "user/update": {
          store.dispatch("loading", false);
          store.dispatch("user/updateList", state.academy.currentAcademy.id);
          break;
        }

        // handle user activation / deactivation
        case "user/updateActive": {
          store.dispatch("loading", false);
          store.dispatch(
            "user/updateActiveList",
            state.academy.currentAcademy.id
          );
          break;
        }
        case "user/updateActiveList": {
          window.location.reload();
          store.dispatch("loading", false);
          break;
        }
        case "user/oneTimeLinkMemberCreate": {
          let key = generateRandomString(28);

          let myUrl =
            "https://app.dojosei.com/academy/" +
            state.academy.currentAcademy.id +
            "/one-time-access/" +
            key;

          let updatedAcademy = JSON.parse(
            JSON.stringify(state.academy.currentAcademy)
          );

          let accessCodes = { ...state.academy.currentAcademy.accessCodes };
          accessCodes[key] = action.payload.id;

          let payload = {};

          updatedAcademy.accessCodes = accessCodes;

          payload.academyId = state.academy.currentAcademy.id;
          payload.academy = updatedAcademy;

          navigator.clipboard.writeText(myUrl);

          store.dispatch("academy/createOneTimeAccessMemberCreate", payload, {
            root: true,
          });
        }
      }
    },
  });
};
export default userPlugin;
