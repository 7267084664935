import { render, staticRenderFns } from "./AppCard.vue?vue&type=template&id=3ab9f7d2&scoped=true&"
import script from "./AppCard.vue?vue&type=script&lang=js&"
export * from "./AppCard.vue?vue&type=script&lang=js&"
import style0 from "./AppCard.vue?vue&type=style&index=0&id=3ab9f7d2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ab9f7d2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard})
