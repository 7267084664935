import firebase from "@/firebase";

const userService = {
  async create(newUser) {
    const response = await firebase
      .firestore()
      .collection("users")
      .add(newUser);

    if (response) {
      return response.id;
    } else {
      return "";
    }
  },

  async set(payload) {
    let response = await firebase
      .firestore()
      .collection("users")
      .doc(payload.id)
      .set({ ...payload.user, accessLevel: "owner" });
    /* 
    let response2 = await firebase
      .firestore()
      .collection("users")
      .doc(payload.id)
      .get();

    // creating data, but response is undefined, so returning false
    console.log(response);
    console.log(response2.data()); */
    if (response) {
      return true;
    } else {
      return false;
    }
  },

  async update(payload) {
    let response = await firebase
      .firestore()
      .collection("users")
      .doc(payload.id)
      .update({ ...payload.member });
    /* 
    let response2 = await firebase
      .firestore()
      .collection("users")
      .doc(payload.id)
      .get();

    // creating data, but response is undefined, so returning false
    console.log(response);
    console.log(response2.data()); */
    if (response) {
      return true;
    } else {
      return false;
    }
  },

  async list(academyId) {
    const response = await firebase
      .firestore()
      .collection("users")
      .orderBy("academies." + academyId || "")
      .get();

    if (response) {
      let userList = [];
      response.forEach((doc) => {
        let user = doc.data();
        user.id = doc.id;
        userList.push(user);
      });
      return userList;
    } else {
      return [];
    }
  },

  async get(userId) {
    const response = await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .get();

    if (response.data() != undefined) {
      let user = response.data();
      user.id = response.id;
      return user;
    } else {
      return {};
    }
  },
};

export default userService;
