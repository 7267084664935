<script>
import LoginTemplate from "../templates/LoginTemplate.vue";

export default {
  components: { LoginTemplate },
  name: "LoginPage",
  data: () => ({
    valid: false,
    showPassword: false,
  }),
  methods: {
    goToSignUp() {
      this.$router.push("signup");
    },
    authenticate(form) {
      /* transfer validation to other place */

      this.$store.dispatch("auth/login", form);
    },
  },
};
</script>

<template>
  <login-template @SignUp="goToSignUp" @LogIn="authenticate" />
</template>
