import firebase from "@/firebase";

const academyService = {
  async list(ownerId) {
    let response = await firebase
      .firestore()
      .collection("academies")
      .where("ownerId", "==", ownerId || "")
      .get();

    let academies = [];
    response.forEach((doc) => {
      let academy = doc.data();
      academy.id = doc.id;
      academies.push(academy);
    });

    return academies;
  },
  async create(newAcademy) {
    const responseReference = await firebase
      .firestore()
      .collection("academies")
      .add(newAcademy);

    const response = await responseReference.get();

    let academy = response.data();
    academy.id = response.id;

    return academy;
  },
  async update(academy) {
    let academyId = academy.id;
    delete academy.id;
    const response = await firebase
      .firestore()
      .collection("academies")
      .doc(academyId)
      .update({
        ...academy,
      });

    if (response) {
      let academy = response.data();
      academy.id = response.id;
      return academy;
    } else {
      return {};
    }
  },
  async get(academyId) {
    const response = await firebase
      .firestore()
      .collection("academies")
      .doc(academyId)
      .get();

    if (response) {
      let academy = response.data();
      academy.id = response.id;
      return academy;
    } else {
      return {};
    }
  },
};

export default academyService;
