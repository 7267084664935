import academyService from "@/services/academyService";

const actions = {
  async list({ commit, dispatch }, payload) {
    try {
      const academies = await academyService.list(payload);
      commit("setAcademies", academies);
    } catch (err) {
      alert("Erro ao Carregar Academias --" + err.message);
      commit("setError", "error", { root: true });
      dispatch("loading", false, { root: true });
    }
  },
  async createOneTimeAccess({ dispatch }, payload) {
    dispatch("loading", true, { root: true });

    try {
      return await academyService.update(payload.academy);
    } catch (err) {
      console.log(err);
      alert("Erro ao Criar Acesso --" + err.message);
    }
    dispatch("loading", false, { root: true });
  },
  async createOneTimeAccessMemberCreate({ dispatch }, payload) {
    dispatch("loading", true, { root: true });

    try {
      return await academyService.update(payload.academy);
    } catch (err) {
      console.log(err);
      alert("Erro ao Criar Acesso --" + err.message);
    }
    dispatch("loading", false, { root: true });
  },
  async getOneTimeAccess({ commit, dispatch }, payload) {
    try {
      const academy = await academyService.get(payload.academyId);

      commit("setCurrentAcademy", academy);
    } catch (err) {
      alert("Erro ao Carregar Academia --" + err.message);
      commit("setError", "error", { root: true });
      dispatch("loading", false, { root: true });
    }
  },
  async consumeOneTimeAccess({ dispatch }, payload) {
    dispatch("loading", true, { root: true });
    try {
      const response = await academyService.update(payload);
      console.log(response);
    } catch (err) {
      console.log(err);
      alert("Erro ao consumir OneTime --" + err.message);
    }
    dispatch("loading", false, { root: true });
  },
  async create({ commit, dispatch }, payload) {
    dispatch("loading", true, { root: true });
    try {
      const academy = await academyService.create(payload);
      commit("setCurrentAcademy", academy);
      commit("setAcademies", [academy]);
      dispatch("user/updateAcademy", academy, { root: true });
    } catch (err) {
      console.log(err);
      alert("Erro ao Criar Academy --" + err.message);
      commit("setError", "error", { root: true });
    }
    dispatch("loading", false, { root: true });
  },
  async get({ commit, dispatch }, payload) {
    try {
      const academy = await academyService.get(payload);

      commit("setCurrentAcademy", academy);
    } catch (err) {
      alert("Erro ao Carregar Academia --" + err.message);
      commit("setError", "error", { root: true });
      dispatch("loading", false, { root: true });
    }
  },
  async refreshAcademy({ commit, dispatch }, payload) {
    try {
      const academy = await academyService.get(payload);

      commit("setCurrentAcademy", academy);
    } catch (err) {
      alert("Erro ao Carregar Academia --" + err.message);
      commit("setError", "error", { root: true });
      dispatch("loading", false, { root: true });
    }
  },
};

export default actions;
