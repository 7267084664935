import Vue from "vue";
import Vuex from "vuex";

import VuexPersistence from "vuex-persist";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import state from "./state";
import modules from "./modules";
import authPlugin from "@/plugins/authPlugin";
import billingPlugin from "@/plugins/billingPlugin";
import userPlugin from "@/plugins/userPlugin";
import oneTimePlugin from "@/plugins/oneTimePlugin";
import academyPlugin from "../plugins/academyPlugin";

Vue.use(Vuex);
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: "dojosei",
});
const debug = process.env.NODE_ENV !== "production";

const store = new Vuex.Store({
  modules,
  state,
  getters,
  mutations,
  actions,
  plugins: [
    vuexLocal.plugin,
    billingPlugin,
    userPlugin,
    oneTimePlugin,
    authPlugin,
    academyPlugin,
  ],
  strict: debug,
});

export default store;
