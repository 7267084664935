import initialState from "./state";

const mutations = {
  setCurrentBilling: (state, payload) => {
    state = payload;
  },
  setBillingList: (state, payload) => {
    state.billingList = payload;
  },
  addBilling: (state, payload) => {
    let newBillingList = [];
    if (state.billingList != undefined) {
      newBillingList = [...state.billingList];
    }
    newBillingList.push(payload);
    state.billingList = newBillingList;
  },
  RESET: (state) => {
    Object.assign(state, initialState);
  },
};

export default mutations;
