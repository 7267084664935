import Vue from "vue";
import VueRouter from "vue-router";
import firebase from "firebase/compat/app";
import LoginPage from "@/components/pages/LoginPage.vue";
import SignupPage from "@/components/pages/SignupPage.vue";
import store from "@/store";
import debounceCall from "@/helpers/debounceCall";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignupPage,
  },
  {
    path: "/",
    name: "HomePage",
    meta: { requiresAuth: true },
    component: () => import("@/components/pages/HomePage.vue"),
  },
  {
    path: "/member/create",
    name: "MemberCreatePage",
    meta: { requiresAuth: true },
    component: () => import("@/components/pages/MemberCreatePage.vue"),
  },
  {
    path: "/member/:id/edit",
    name: "MemberEditPage",
    meta: { requiresAuth: true },
    props: true,
    component: () => import("@/components/pages/MemberEditPage.vue"),
  },
  {
    path: "/member/:id",
    name: "MemberShowPage",
    meta: { requiresAuth: true },
    props: true,
    component: () => import("@/components/pages/MemberShowPage.vue"),
  },
  {
    path: "/member/:id/billing/create",
    name: "BillingCreatePage",
    meta: { requiresAuth: true },
    props: true,
    component: () => import("@/components/pages/BillingCreatePage.vue"),
  },
  {
    path: "/academy/:id/billing/create",
    name: "ExpenseCreatePage",
    meta: { requiresAuth: true },
    props: true,
    component: () => import("@/components/pages/ExpenseCreatePage.vue"),
  },
  {
    path: "/academy/:id/billing/:billingId/edit",
    name: "ExpenseEditPage",
    meta: { requiresAuth: true },
    props: true,
    component: () => import("@/components/pages/ExpenseEditPage.vue"),
  },
  {
    path: "/member/:id/billing/:billingId/edit",
    name: "BillingEditPage",
    meta: { requiresAuth: true },
    props: true,
    component: () => import("@/components/pages/BillingEditPage.vue"),
  },
  {
    path: "/member/:id/billing/history",
    name: "BillingHistoryPage",
    meta: { requiresAuth: true },
    props: true,
    component: () => import("@/components/pages/BillingHistoryPage.vue"),
  },
  {
    path: "/academy/create",
    name: "AcademyCreatePage",
    meta: { requiresAuth: true },
    props: true,
    component: () => import("@/components/pages/AcademyCreatePage.vue"),
  },
  {
    path: "/academy/:id/one-time-access/:hash",
    name: "OneTimeAccessPage",
    meta: { requiresAuth: false },
    props: true,
    component: () => import("@/components/pages/OneTimeAccessPage.vue"),
  },
  {
    path: "/one-time-access/thank-you",
    name: "OneTimeSuccessPage",
    meta: { requiresAuth: false },
    props: true,
    component: () => import("@/components/pages/OneTimeSuccessPage.vue"),
  },
  {
    path: "/one-time-access/link-expired",
    name: "OneTimeExpiredPage",
    meta: { requiresAuth: false },
    props: true,
    component: () => import("@/components/pages/OneTimeExpiredPage.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes, // short for `routes: routes`
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // need to login
    firebase.auth().onAuthStateChanged((user) => {
      if (user != null && user != undefined) {
        if (store?.state?.auth?.currentAuth == undefined) {
          debounceCall(
            () => store.dispatch("auth/setAuth", user.multiFactor.user),
            1000
          );
        }
        return next();
      } else {
        store.dispatch("logout");
      }
    });
  } else {
    if (
      (to.path === "/login" || to.path === "/signup") &&
      from.path != "/login" &&
      from.path != "/signup" &&
      from.path != "/" &&
      store?.state?.auth?.currentAuth != undefined
    ) {
      return next("/");
    } else {
      return next();
    }
  }
});

export default router;
