<script>
import AppLoading from "./components/atoms/AppLoading.vue";
import { mapGetters } from "vuex";

export default {
  name: "DojoSei",
  components: {
    AppLoading,
  },
  computed: {
    loading: function () {
      return this.$store.getters.loading;
    },
    ...mapGetters({
      currentAuth: "auth/currentAuth",
    }),
    isMobile() {
      return window.innerWidth < 1024;
    },
  },
  created() {
    if (this.currentAuth === null) {
      this.$router.push("/login");
    }
  },
  mounted() {
    if (this.loading === true) {
      this.$store.dispatch("logout");
    }
    this.updateHeight();
    window.addEventListener("resize", this.updateHeight);
    /* if (this.isiPhone()) {
      document
        .getElementById("app-container")
        .style.setProperty("padding-top", "80px", "important");
    } */
  },
  destroyed() {
    window.removeEventListener("resize", this.updateHeight);
  },
  updated() {
    // We listen to the resize event
    window.addEventListener("resize", () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  },
  methods: {
    updateHeight() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
    isiPhone() {
      return !!navigator.userAgent.match(/(iPhone)/i);
    },
  },
};
</script>

<template>
  <v-app>
    <v-main>
      <v-container
        id="app-container"
        fill-height
        fluid
        ma-0
        pa-0
        style="margin-bottom: 56px !important"
      >
        <!-- system bar 
          <v-system-bar
          v-if="isMobile"
          color="white"
          height="30"
          fixed
          light
          style="width: 100%"
        >
          <v-spacer></v-spacer>
          <v-icon>mdi-wifi-strength-4</v-icon>
          <v-icon>mdi-signal-cellular-outline</v-icon>
          <v-icon>mdi-battery</v-icon>
          <span>12:30</span>
        </v-system-bar> -->
        <router-view v-if="!loading" />

        <app-loading v-else />
      </v-container>
    </v-main>
  </v-app>
</template>

<style lang="scss">
* {
  box-sizing: border-box !important;
  cursor: default;
  user-select: none;
  line-height: 100%;
}

input {
  cursor: text;
}

p {
  margin: 0 !important;
}

html,
body {
  overflow-y: hidden;
}

// #app-container {
//   max-height: 100% !important;
// }

html,
body,
.container {
  margin: 0;
  padding: 0;
  margin: 0;
  padding: 0;
  max-height: calc(var(--vh, 1vh) * 100) !important;
  min-height: -webkit-fill-available;
  width: 100vw !important;
  font-family: "Nunito";
  max-width: 100%;
  overflow-x: hidden !important;
}

.v-application,
.v-main {
  max-height: calc(var(--vh, 1vh) * 100) !important;
}

.container.container--fluid .scrollable {
  max-height: calc((var(--vh, 1vh) * 100) - 56px) !important;
}

.container {
  overflow-y: scroll !important;
  max-width: 100vw !important;
}

.container.fill-height {
  max-height: calc(var(--vh, 1vh) * 100) !important;
  align-items: center !important;
  justify-content: center !important;
  background-image: url("assets/bg-mobile.png");
  background-size: cover;

  @media screen and (min-width: 768px) {
    background-image: url("assets/bg-desktop.png");
  }
}
.container.container--fluid {
  overflow-y: visible !important;
  align-items: flex-start !important;
}

.screen-container {
  min-height: 100%;
  min-width: 100%;
  background-color: #ffffff !important;
}

.page-title {
  width: 100%;
  margin: 16px auto;
  text-align: center;
  font-size: 32px;
  font-weight: 600;
}

@font-face {
  font-family: "Nunito";
  src: url("./assets/fonts/Nunito/Nunito-ExtraLight.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("./assets/fonts/Nunito/Nunito-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("./assets/fonts/Nunito/Nunito-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("./assets/fonts/Nunito/Nunito-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("./assets/fonts/Nunito/Nunito-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("./assets/fonts/Nunito/Nunito-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("./assets/fonts/Nunito/Nunito-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("./assets/fonts/Nunito/Nunito-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Quicksand";
  src: url("./assets/fonts/Quicksand/Quicksand-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Quicksand";
  src: url("./assets/fonts/Quicksand/Quicksand-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Quicksand";
  src: url("./assets/fonts/Quicksand/Quicksand-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Quicksand";
  src: url("./assets/fonts/Quicksand/Quicksand-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Quicksand";
  src: url("./assets/fonts/Quicksand/Quicksand-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
</style>
