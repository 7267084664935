const userFilter = [
  {
    value: "active",
    text: "Ativos",
  },
  {
    value: "inactive",
    text: "Inativos",
  },
  {
    value: "all",
    text: "Todos",
  },
];

export default userFilter;
