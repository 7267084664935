<script>
export default {
  name: "AppImage",
  data() {
    return {};
  },
  props: {
    customStyle: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "dojosei-logo",
    },
  },
  methods: {
    getImgUrl(pet) {
      var images = require.context("../../assets/", false, /\.svg$/);
      if (pet.length > 0) {
        return images("./" + pet + ".svg");
      }
      return images("./dojosei-logo.svg");
    },
  },
};
</script>

<template>
  <img
    class="image"
    :src="getImgUrl(image)"
    :style="customStyle + ' height: 10%; max-height: 20%;'"
    contain
  />
</template>

<style lang="scss">
.image {
  width: 100%;
  margin: 0 auto;
}
</style>
