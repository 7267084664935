import router from "../router";

const academyPlugin = (store) => {
  store.subscribeAction({
    after: (action) => {
      switch (action.type) {
        // handling academy creation flow:
        // updateAcademyUser + goToCreateHome
        case "user/updateAcademy":
          router.push("/");
          break;

        case "academy/createOneTimeAccessMemberCreate": {
          router.push("/");
          store.dispatch("loading", false, { root: true });
          break;
        }
      }
    },
  });
};
export default academyPlugin;
