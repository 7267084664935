import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import state from "./state";

const billingStore = {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};

export default billingStore;
