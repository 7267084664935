import userStore from "./user";
import billingStore from "./billing";
import authStore from "./auth";
import academyStore from "./academy";

const modules = {
  auth: authStore,
  user: userStore,
  billing: billingStore,
  academy: academyStore,
};

export default modules;
