<script>
export default {
  name: "AppLoading",
};
</script>

<template>
  <div class="text-center">
    <v-overlay>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
