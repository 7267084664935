<script>
import AppForm from "../atoms/AppForm.vue";
import AppButton from "../atoms/AppButton.vue";
import AppCard from "../atoms/AppCard.vue";
import AppImage from "../atoms/AppImage.vue";

export default {
  components: { AppForm, AppButton, AppImage, AppCard },
  name: "LoginTemplate",
  methods: {
    handleChange(payload) {
      this.form.email = payload.fields.email?.replace(" ", "");
      this.form.password = payload.fields.password?.replace(" ", "");
      if (this.form.email?.length > 5 && this.form.password?.length > 5) {
        this.disabledLogin = false;
      } else {
        this.disabledLogin = true;
      }
    },
    emit(eventName) {
      this.$emit(eventName, this.form);
    },
  },
  data: () => ({
    formName: "auth-form",
    form: {},
    disabledLogin: true,
    validation: {
      password: [
        (value) => !!value || "Password is required.",
        (value) => value?.length >= 8 || "Min 8 characters",
      ],
      email: [
        (value) => !!value || "E-mail is required",
        (value) => /.+@.+\..+/.test(value) || "E-mail must be valid",
      ],
    },
    inputList: {
      email: {
        value: "",
        hidden: false,
        disabled: false,
        required: true,
        keyboardType: "email",
        label: "E-mail",
        name: "email",
        inputType: "text",
        dateType: "",
      },
      password: {
        value: "",
        hidden: true,
        disabled: false,
        required: true,
        keyboardType: "",
        label: "Senha",
        name: "password",
        inputType: "password",
        dateType: "",
      },
    },
  }),
};
</script>

<template>
  <section id="login-container" class="section-container">
    <app-card class="card-form" style="width: 100%; max-width: 448px">
      <app-image
        customStyle="transform: translateX(-6px); max-width: 278px; margin-top: 80px; margin-bottom: 66px;"
      />
      <span>
        <h2 class="card-form__title">Boas-vindas ao DojoSei!</h2>
        <h4 class="card-form__subtitle">Digite seu Email e Senha abaixo.</h4>
      </span>
      <app-form
        :formName="formName"
        :validation="validation"
        :inputList="inputList"
        subtitle=""
        class="card-form__form"
        @change="handleChange"
        @submit="emit('LogIn')"
      >
        <span class="card-form__form__buttons">
          <app-button
            name="login"
            text="Entrar"
            type="new-default"
            icon="mdi-chevron-right"
            iconPosition="right"
            @click="emit('LogIn')"
          />
          <!-- <app-button
            name="login"
            text="Entrar"
            color="orange accent-2"
            customStyle="color: white;"
            type="default"
            :disabled="disabledLogin"
            @click="emit('LogIn')"
          /> -->
          <div class="AppButton-link">
            <span class="AppButton-link__text">Ainda não é cadastrado? </span>
            <span class="AppButton-link__link" @click="emit('SignUp')"
              >Crie sua conta aqui.</span
            >
          </div>
        </span>
      </app-form>
    </app-card>
  </section>
</template>

<style lang="scss">
#login-container {
  min-height: calc(var(--vh, 1vh) * 100) !important;

  padding: 0px 16px;
  width: 100%;
  display: flex;
  justify-content: center !important;
}
.AppButton-link {
  margin: 32px auto;
  display: flex;
  gap: 4px;
  justify-content: center;

  .AppButton-link__text {
    font-size: 14px;
    color: #8e8e8e;
    font-weight: 500;
  }

  .AppButton-link__link {
    font-size: 14px;
    color: #94accf;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
  }
}
.card-form {
  padding: 16px;
  margin: 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: default;
}

.card-form__form {
  margin-top: 32px;
}
.card-form__title {
  width: 90vw;
  margin: 0;
  margin-left: 4px;
  text-align: left;
  font-size: 22px;
  font-weight: 600;
}
.card-form__subtitle {
  color: #8e8e8e;
  width: 90vw;
  margin: 0;
  margin-left: 4px;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}
</style>
