const getters = {
  billingList: (state) => {
    return state.billingList != undefined ? state.billingList : [];
  },
  hasBilling: (state) => {
    return state.billingList != undefined;
  },
};

export default getters;
