<script>
import AppForm from "../atoms/AppForm.vue";
import AppButton from "../atoms/AppButton.vue";
import AppCard from "../atoms/AppCard.vue";
import AppImage from "../atoms/AppImage.vue";

export default {
  components: { AppForm, AppCard, AppButton, AppImage },
  name: "SignupTemplate",
  methods: {
    handleChange(payload) {
      Object.keys(payload.fields).forEach((field) => {
        this.form[field] = payload.fields[field];
      });
      this.form.email = payload.fields.email?.replace(" ", "");
      this.form.password = payload.fields.password?.replace(" ", "");
      if (
        this.form.name?.length > 3 &&
        this.form.email?.length > 5 &&
        this.form.password?.length > 5 &&
        this.form.password === this.form.confirmPassword
      ) {
        this.disabledSignup = false;
      } else {
        this.disabledSignup = true;
      }
    },
    emit(eventName) {
      this.$emit(eventName, this.form);
    },
  },
  data: () => ({
    formName: "login-form",
    form: {},
    disabledSignup: true,
    validation: {
      password: [
        (value) => !!value || "Password is required.",
        (value) => value?.length >= 8 || "Min 8 characters",
      ],
      email: [
        (value) => !!value || "E-mail is required",
        (value) => /.+@.+\..+/.test(value) || "E-mail must be valid",
      ],
      name: [
        (value) => !!value || "Name is required",
        (value) => /.+@.+\..+/.test(value) || "Name must be valid",
      ],
    },
    inputList: {
      name: {
        value: "",
        hidden: false,
        disabled: false,
        required: true,
        keyboardType: "name",
        label: "Nome Completo",
        name: "name",
        inputType: "text",
        dateType: "",
      },
      email: {
        value: "",
        hidden: false,
        disabled: false,
        required: true,
        keyboardType: "email",
        label: "E-mail",
        name: "email",
        inputType: "text",
        dateType: "",
      },
      password: {
        value: "",
        hidden: true,
        disabled: false,
        required: true,
        keyboardType: "",
        label: "Senha",
        name: "password",
        inputType: "password",
        dateType: "",
      },
      confirmPassword: {
        value: "",
        hidden: true,
        disabled: false,
        required: true,
        keyboardType: "",
        label: "Confirmação de Senha",
        name: "confirmPassword",
        inputType: "password",
        dateType: "",
      },
    },
  }),
};
</script>

<template>
  <section id="signup-container" class="section-container">
    <app-card class="card-form" style="width: 100%; max-width: 448px">
      <app-image
        customStyle="transform: translateX(-6px); max-width: 278px; margin-top: 80px; margin-bottom: 66px;"
      />
      <span>
        <h2 class="card-form__title">Boas-vindas ao DojoSei!</h2>
        <h4 class="card-form__subtitle">
          Cadastre-se inserindo seus dados abaixo.
        </h4>
      </span>
      <app-form
        :formName="formName"
        :validation="validation"
        :inputList="inputList"
        subtitle=""
        class="card-form__form"
        @change="handleChange"
        @submit="emit('SignUp')"
      >
        <span class="card-form__form__buttons">
          <app-button
            name="login"
            text="Cadastrar"
            type="new-default"
            icon="mdi-chevron-right"
            iconPosition="right"
            @click="emit('SignUp')"
          />
          <!-- <app-button
            name="login"
            text="Entrar"
            color="orange accent-2"
            customStyle="color: white;"
            type="default"
            :disabled="disabledLogin"
            @click="emit('LogIn')"
          /> -->
          <div class="AppButton-link">
            <span class="AppButton-link__text">Já possui uma conta? </span>
            <span class="AppButton-link__link" @click="emit('LogIn')"
              >Acesse clicando aqui.</span
            >
          </div>
        </span>
      </app-form>
    </app-card>
  </section>
</template>

<style lang="scss">
#signup-container {
  min-height: calc(var(--vh, 1vh) * 100) !important;

  padding: 0px 16px;
  width: 100%;
  display: flex;
  justify-content: center !important;
}
.section-container {
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100vw;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  gap: 32px;

  // @media screen and (min-width: 1200px) {
  //   padding: 108px 16px;
  // }
}
.page-title {
  width: 90vw;
  margin: 0 auto;
  text-align: center;
  font-size: 32px;
  font-weight: 600;
}

.page-subtitle {
  text-align: center;
  font-weight: 500;
  width: 80%;
  margin-bottom: 16px;
  font-size: 18px;
  margin: 0 auto;
  color: #757575;
}
</style>
