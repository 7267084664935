import userOrder from "@/constants/user-order";
import initialState from "./state";

const mutations = {
  orderUsers: (state, payload) => {
    state.order = userOrder.find((e) => e.value === payload);
  },
  setUser: (state, payload) => {
    state.currentUser = payload;
  },
  setOneTime: (state, payload) => {
    state.oneTime = payload;
  },
  clearUser: (state) => {
    state.currentUser = null;
  },
  setUsers: (state, payload) => {
    state.users = payload;
  },
  RESET: (state) => {
    Object.assign(state, initialState);
  },
};

export default mutations;
