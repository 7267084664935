import router from "../router";

const billingPlugin = (store) => {
  store.subscribeAction({
    after: (action) => {
      switch (action.type) {
        case "billing/create": {
          store.dispatch("loading", false);
          store.dispatch("billing/list", action.payload.academyId);
          break;
        }
        case "billing/update": {
          store.dispatch("loading", true);
          store.dispatch("billing/list", action.payload.billing.academyId);
          break;
        }
        case "billing/delete": {
          store.dispatch("loading", true);
          store.dispatch("billing/listDelete", action.payload.academyId);
          break;
        }
        case "billing/listDelete": {
          store.dispatch("loading", false);
          break;
        }
        case "billing/list": {
          store.dispatch("loading", false);
          router.go(-1);
          break;
        }
      }
    },
  });
};
export default billingPlugin;
