<script>
export default {
  name: "AppCard",
  data() {
    return {};
  },
  props: {
    customClass: {
      type: String,
      default: "",
    },
    customStyle: {
      type: String,
      default: "",
    },
    color: String,
    rounded: Boolean,
    customHeight: String,
    ripple: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    click(event) {
      this.$emit("click", event);
    },
  },
};
</script>

<template>
  <v-card
    :class="customClass + ' app-card'"
    :style="customStyle"
    :color="color"
    :height="customHeight"
    :ripple="ripple"
    @click="click"
  >
    <slot />
  </v-card>
</template>

<style lang="scss" scoped>
.text-white {
  color: #ffffff;
}
.v-card--link:focus:before {
  opacity: 0 !important;
}

.text-gray {
  color: #797979;
}
.app-card {
  border-radius: 20px;
  box-shadow: 0px 4px 34px rgba(33, 76, 158, 0.25) !important;
  cursor: default;
}
</style>
