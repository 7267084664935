import router from "../router";

const oneTimePlugin = (store) => {
  store.subscribeAction({
    after: (action) => {
      switch (action.type) {
        case "academy/createOneTimeAccess": {
          store.dispatch("academy/refreshAcademy", action.payload.academyId);
          break;
        }
        case "academy/refreshAcademy": {
          store.dispatch("loading", false);
          /* router.go(-1); */
          break;
        }
        case "academy/getOneTimeAccess": {
          store.dispatch(
            "user/getOneTime",
            store.state.academy.currentAcademy.accessCodes[action.payload.hash]
          );
          let academy = store.state.academy.currentAcademy;
          delete academy.accessCodes[action.payload.hash];
          academy.id = action.payload.academyId;
          store.dispatch("academy/consumeOneTimeAccess", academy);

          break;
        }
        case "user/getOneTime": {
          store.dispatch("loading", false);
          break;
        }
        case "user/oneTimeUpdate": {
          store.dispatch("oneTimeFinish");
          router.push("one-time-access/thank-you");
          break;
        }
      }
    },
  });
};
export default oneTimePlugin;
