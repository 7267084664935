import firebase from "@/firebase";

const authService = {
  async signup(user) {
    const response = await firebase
      .auth()
      .createUserWithEmailAndPassword(user.email, user.password);

    return Object.freeze(response.user.multiFactor.user);
  },
  async login(user) {
    const response = await firebase
      .auth()
      .signInWithEmailAndPassword(user.email, user.password);

    return Object.freeze(response.user.multiFactor.user);
  },
};

export default authService;
