<script>
import AppInput from "../atoms/AppInput.vue";

export default {
  name: "AppForm",
  components: {
    AppInput,
  },
  data() {
    return {
      form: {},
    };
  },
  mounted() {
    for (var input in this.inputList) {
      if (input != undefined) {
        this.form[input] = this.inputList[input].value
          ? this.inputList[input].value
          : "";
      }
    }

    this.emit({ name: "change", formName: this.formName, value: this.form });
  },
  props: {
    formName: String,
    validation: Object,
    /* still to make validation object */
    inputList: Object,
  },
  watch: {
    inputList: {
      handler(val) {
        Object.keys(val).forEach((key) => {
          this.form[key] = val[key].value;
        });
        /* this.updateValue({ name: name, value: value }); */
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleChange({ name, value }) {
      this.updateValue({ name: name, value: value });
      this.emit({
        name: "change",
        formName: this.formName,
        value: this.form,
      });
    },
    updateValue({ name, value }) {
      this.form[name] = value;
    },
    submit() {
      this.emit({
        name: "submit",
        formName: this.formName,
        value: this.form,
      });
    },
    emit({ name, formName, value }) {
      this.$emit(name, { name: formName, fields: value });
    },
  },
};
</script>

<template>
  <v-form
    ref="formName"
    lazy-validation
    class="align-center justify-center"
    style="width: 100%; max-width: 448px"
  >
    <div class="form__inputs">
      <app-input
        v-for="input in inputList"
        v-bind:key="input.name"
        :value="input.value"
        :disabled="input.disabled"
        :required="input.required"
        :keyboardType="input.keyboardType"
        :label="input.label"
        :name="input.name"
        :mask="input.mask"
        :inputType="input.inputType"
        :dateType="input.dateType"
        :appendIcon="input.appendIcon"
        :items="input.items"
        @change="handleChange"
        @enter="submit"
      />
    </div>
    <slot />
  </v-form>
</template>

<style lang="scss">
.form__inputs {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 32px;
}

.stepper-form {
  display: flex;
  gap: 16px;
  width: 100%;
}
.form-buttons {
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
  overflow: visible;

  @media screen and (min-width: 768px) {
    max-width: 448px;
  }
}
h3 {
  margin-left: 8px;
  font-size: 20px;
  text-align: left;
  font-weight: 600;
  padding-bottom: 24px;
}
</style>
