import userOrder from "@/constants/user-order";
import userFilter from "@/constants/user-filter";

const initialState = () => ({
  currentUser: undefined,
  users: undefined,
  oneTime: undefined,
  order: userOrder.find((e) => e.value === "name"),
  filter: userFilter.find((e) => e.value === "active"),
});

const state = initialState();

export default state;
