const userOrder = [
  {
    value: "name",
    text: "A-Z",
  },
  {
    value: "grad",
    text: "Graduação",
  },
  {
    value: "billing",
    text: "Mensalidade",
  },
];

export default userOrder;
