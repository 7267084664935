import store from "@/store";
import userService from "../../services/userService";

const actions = {
  order({ commit, dispatch }, payload) {
    dispatch("loading", true, { root: true });
    commit("orderUsers", payload);

    dispatch("loading", false, { root: true });
  },
  filter({ commit, dispatch }, payload) {
    dispatch("loading", true, { root: true });
    commit("filterUsers", payload);

    dispatch("loading", false, { root: true });
  },
  async create({ dispatch }, payload) {
    dispatch("loading", true, { root: true });
    try {
      const success = await userService.create(payload.member);
      payload.id = success;
    } catch (err) {
      alert("Erro ao Criar User --" + err.message);
      dispatch("loading", false, { root: true });
    }
  },
  async oneTimeLinkMemberCreate({ dispatch }, payload) {
    dispatch("loading", true, { root: true });
    try {
      const success = await userService.create(payload.member);
      payload.id = success;
    } catch (err) {
      alert("Erro ao Criar User --" + err.message);
      dispatch("loading", false, { root: true });
    }
  },
  async set({ dispatch }, payload) {
    try {
      await userService.set(payload);
    } catch (err) {
      alert("Erro ao Criar User --" + err.message);
      dispatch("loading", false, { root: true });
    }
  },
  async signupSet({ dispatch }, payload) {
    try {
      await userService.set(payload);
    } catch (err) {
      alert("Erro ao Criar User --" + err.message);
      dispatch("loading", false, { root: true });
    }
  },

  async update({ dispatch }, payload) {
    try {
      await userService.update(payload);
    } catch (err) {
      alert("Erro ao Criar User --" + err.message);
      dispatch("loading", false, { root: true });
    }
  },
  async updateActive({ dispatch }, payload) {
    try {
      await userService.update(payload);
    } catch (err) {
      alert("Erro ao Criar User --" + err.message);
      dispatch("loading", false, { root: true });
    }
  },
  async updateActiveList({ commit, dispatch }, payload) {
    try {
      const userList = await userService.list(payload);
      commit("setUsers", userList);
      /* router.push("/"); */
    } catch (error) {
      console.log("Error getting users: ", error);
      dispatch("loading", false, { root: true });
    }
  },
  async oneTimeUpdate({ dispatch }, payload) {
    try {
      await userService.update(payload);
    } catch (err) {
      alert("Erro ao Criar User --" + err.message);
      dispatch("loading", false, { root: true });
    }
  },
  async updateAcademy({ commit, dispatch }, payload) {
    let user = JSON.parse(JSON.stringify(store.state.user.currentUser));
    user.academies = [];
    user.academies.push(payload.id);
    let userId = user.id;
    delete user.id;
    try {
      await userService.set({ id: userId, user: user });
      commit("setUser", user);
      /* router.push("/"); */
    } catch (error) {
      console.log("Error getting users: ", error);
      dispatch("loading", false, { root: true });
    }
  },
  async list({ commit, dispatch }, payload) {
    try {
      const userList = await userService.list(payload);
      commit("setUsers", userList);
      /* router.push("/"); */
    } catch (error) {
      console.log("Error getting users: ", error);
      dispatch("loading", false, { root: true });
    }
  },
  async createList({ commit, dispatch }, payload) {
    try {
      const userList = await userService.list(payload);
      commit("setUsers", userList);
      /* router.push("/"); */
    } catch (error) {
      console.log("Error getting users: ", error);
      dispatch("loading", false, { root: true });
    }
  },
  async updateList({ commit, dispatch }, payload) {
    try {
      const userList = await userService.list(payload);
      commit("setUsers", userList);
      /* router.push("/"); */
    } catch (error) {
      console.log("Error getting users: ", error);
      dispatch("loading", false, { root: true });
    }
  },
  async loginList({ commit, dispatch }, payload) {
    try {
      const userList = await userService.list(payload);
      commit("setUsers", userList);
      /* router.push("/"); */
    } catch (error) {
      console.log("Error getting users: ", error);
      dispatch("loading", false, { root: true });
    }
  },
  async get({ commit, dispatch }, payload) {
    dispatch("loading", true, { root: true });
    try {
      const user = await userService.get(payload);
      commit("setUser", user);
    } catch (err) {
      console.log("Error getting single user: " + err);
      dispatch("loading", false, { root: true });
    }
  },
  async loginGet({ commit, dispatch }, payload) {
    dispatch("loading", true, { root: true });
    try {
      const user = await userService.get(payload);
      commit("setUser", user);
    } catch (err) {
      console.log("Error getting single user: " + err);
      dispatch("loading", false, { root: true });
    }
  },
  async signupGet({ commit, dispatch }, payload) {
    dispatch("loading", true, { root: true });
    try {
      const user = await userService.get(payload);
      commit("setUser", user);
    } catch (err) {
      console.log("Error getting single user: " + err);
      dispatch("loading", false, { root: true });
    }
  },
  async getOneTime({ commit, dispatch }, payload) {
    dispatch("loading", true, { root: true });
    try {
      const user = await userService.get(payload);
      commit("setOneTime", user);
    } catch (err) {
      console.log("Error getting single user: " + err);
      dispatch("loading", false, { root: true });
    }
  },
};

export default actions;
