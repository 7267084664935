import billingService from "@/services/billingService";

const actions = {
  async create({ dispatch, commit }, payload) {
    dispatch("loading", true, { root: true });
    try {
      const billing = await billingService.create(payload.academyId, {
        ...payload,
      });
      commit("addBilling", billing);
    } catch (err) {
      alert("Erro ao Criar Billing --" + err.message);
      console.log(err);
    }
    dispatch("loading", false, { root: true });
  },
  async userCreate({ dispatch, commit }, payload) {
    dispatch("loading", true, { root: true });
    try {
      const billing = await billingService.create(payload.academyId, {
        ...payload,
      });
      commit("addBilling", billing);
    } catch (err) {
      alert("Erro ao Criar Billing --" + err.message);
      console.log(err);
    }
    dispatch("loading", false, { root: true });
  },
  async update({ dispatch }, payload) {
    dispatch("loading", true, { root: true });
    try {
      let billingData = { ...payload.billing };
      await billingService.update(payload.billing.academyId, payload.id, {
        ...billingData,
      });

      /* dispatch("user/updateBilling", billing, { root: true }); */
      /* commit("Billing", billing); */
    } catch (err) {
      alert("Erro ao Editar Billing --" + err.message);
      console.log(err);
    }
    dispatch("loading", false, { root: true });
  },
  async delete({ dispatch }, payload) {
    dispatch("loading", true, { root: true });
    try {
      await billingService.delete(payload.academyId, payload.id);

      /* dispatch("user/updateBilling", billing, { root: true }); */
      /* commit("Billing", billing); */
    } catch (err) {
      alert("Erro ao Criar Billing --" + err.message);
      console.log(err);
    }
    dispatch("loading", false, { root: true });
  },
  async list({ commit, dispatch }, payload) {
    try {
      const billingList = await billingService.list(payload);
      commit("setBillingList", billingList);
    } catch (err) {
      alert("Erro ao Criar Billing --" + err.message);
      commit("setStatus", "error");
      dispatch("loading", false, { root: true });
    }
  },
  async listDelete({ commit, dispatch }, payload) {
    try {
      const billingList = await billingService.list(payload);
      commit("setBillingList", billingList);
    } catch (err) {
      alert("Erro ao Criar Billing --" + err.message);
      commit("setStatus", "error");
      dispatch("loading", false, { root: true });
    }
  },
  async loginList({ commit, dispatch }, payload) {
    try {
      const billingList = await billingService.list(payload);
      commit("setBillingList", billingList);
    } catch (err) {
      alert("Erro ao Criar Billing --" + err.message);
      commit("setStatus", "error");
      dispatch("loading", false, { root: true });
    }
  },
};

export default actions;
