<script>
export default {
  name: "AppButton",
  data() {
    return {
      iconForeground: "#607ecc",
      iconShadow: "#0085ff",
    };
  },
  created() {
    if (this.iconColor === "green") {
      (this.iconForeground = "#748C40"), (this.iconShadow = "#8FFF00");
    }
    if (this.iconColor === "red") {
      (this.iconForeground = "#702520"), (this.iconShadow = "#C30000");
    }
    if (this.iconColor === "maroon") {
      (this.iconForeground = "#534D3C"), (this.iconShadow = "#534D3C");
    }
  },
  props: {
    name: String,
    text: String,
    icon: String,
    iconPosition: {
      type: String,
      default: "left",
    },
    color: String,
    iconColor: String,
    type: String,
    borderRadius: {
      type: String,
      default: "20px",
    },
    disabled: Boolean,
    on: {
      type: Object,
      default: () => {},
    },
    width: {
      type: String,
      default: "100%",
    },
    customStyle: {
      type: String,
      default: "",
    },
    customContainerStyle: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "40",
    },
  },
  methods: {
    emit(name) {
      this.$emit(name);
    },
  },
};
</script>

<template>
  <span :style="'display: block; width: ' + width + ';' + customContainerStyle">
    <v-btn
      v-if="type === 'icon'"
      :name="name"
      :color="color"
      depressed
      :width="size"
      :height="size"
      v-on="on"
      :style="
        'border-radius: ' +
        borderRadius +
        '; background: rgba(255, 255, 255, 0.5);border: 1px solid rgba(148, 172, 207, 0.4) !important;box-shadow: 0px 4px 34px rgba(33, 76, 158, 0.25); ' +
        customStyle
      "
      @click="emit('click')"
    >
      <v-icon
        size="size"
        :color="iconForeground"
        :style="
          'text-shadow: 0px 0px 20px ' + iconShadow + '; color: ' + iconColor
        "
        >{{ icon }}</v-icon
      >
    </v-btn>

    <v-btn
      v-if="type === 'default'"
      :name="name"
      :color="color"
      :disabled="disabled"
      @click="emit('click')"
      height="46"
      :style="
        customStyle +
        ' width: ' +
        width +
        '; !important; border-radius: 20px; background: rgba(255, 255, 255, 0.5); box-shadow: 0px 4px 34px rgba(33, 76, 158, 0.25);'
      "
    >
      {{ text }}
    </v-btn>

    <v-btn
      v-if="type === 'new-default'"
      :name="name"
      :color="color"
      :disabled="disabled"
      @click="emit('click')"
      height="40"
      depressed
      class="btn-default"
      :style="
        customStyle +
        'height: 36px; width: 100%; text-transform: unset !important; font-size: 16px !important; font-weight: 500; border-radius: 8px;'
      "
    >
      <v-icon
        v-if="icon != undefined && iconPosition === 'left'"
        size="32"
        left
      >
        {{ icon }}
      </v-icon>
      {{ text }}
      <v-icon
        v-if="icon != undefined && iconPosition === 'right'"
        size="32"
        right
      >
        {{ icon }}
      </v-icon>
    </v-btn>
    <v-btn
      v-if="type === 'new-return'"
      :name="name"
      :color="color"
      :disabled="disabled"
      @click="emit('click')"
      height="40"
      depressed
      :style="
        customStyle +
        'height: 36px; letter-spacing: 1; width: 100%; text-transform: unset !important; font-size: 16px; font-weight: 500; border-radius: 8px; color: #94ACCF; background-color: #FFF; border: 1px solid #94ACCF;'
      "
    >
      <v-icon
        v-if="icon != undefined && iconPosition === 'left'"
        size="24"
        left
      >
        {{ icon }}
      </v-icon>
      {{ text }}
      <v-icon
        v-if="icon != undefined && iconPosition === 'right'"
        size="24"
        right
      >
        {{ icon }}
      </v-icon>
    </v-btn>
    <v-btn
      v-if="type === 'new-button-with-icon'"
      :name="name"
      :color="color"
      :disabled="disabled"
      @click="emit('click')"
      height="26"
      depressed
      :style="
        customStyle +
        ' width: 100%; font-size: 16px; font-weight: 500; border-radius: 8px; color: #94ACCF; background-color: #FFF; border: 1px solid #94ACCF;'
      "
    >
      {{ text }}

      <v-icon right>
        {{ icon }}
      </v-icon>
    </v-btn>

    <v-fab-transition v-if="type === 'fab'">
      <v-btn
        :color="color"
        fab
        large
        dark
        bottom
        fixed
        right
        class="v-btn--example"
        @click="emit('fabClick')"
      >
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </v-fab-transition>
  </span>
</template>

<style lang="scss">
.v-btn > .v-btn__content .v-icon {
  margin: 0 auto;
}

.button-style {
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #94accf;
  box-shadow: 0px 4px 34px rgba(33, 76, 158, 0.25);
  backdrop-filter: blur(100px);
}

.btn-default {
  text-transform: unset !important;
  width: 100%;
  font-size: 12px !important;
  letter-spacing: normal !important;
  border-radius: 8px !important;
  color: #fff !important;
  background-color: #f2aa55 !important;
  height: 36px !important;

  .v-btn__content {
    font-weight: 700 !important;
  }
}

.v-btn span {
  font-weight: 600;
  cursor: pointer;
}

.v-btn .v-icon {
  cursor: pointer;
}
</style>
