const getters = {
  members: (state) => {
    return state.users
      ? state.users.filter((user) => user.accessLevel === "user")
      : undefined;
  },
  currentUser: (state) => {
    return state.currentUser;
  },
  currentSorting: (state) => {
    return state.order.value;
  },
  currentFiltering: (state) => {
    return state.filter.value;
  },
};

export default getters;
