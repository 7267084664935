const getters = {
  hasAcademy: (state) => {
    return (
      state.currentAcademy != undefined &&
      Object.keys(state.currentAcademy).length > 0
    );
  },
  currentAcademy: (state) => {
    return state.currentAcademy ? state.currentAcademy : undefined;
  },
};

export default getters;
