import router from "../router";
import modules from "./modules";

const actions = {
  logout({ commit, dispatch }) {
    Object.keys(modules).forEach((moduleName) => {
      commit(`${moduleName}/RESET`);
    });
    window.localStorage.removeItem("dojosei");
    dispatch("loading", false);
    if (router.currentRoute.fullPath != "/login") router.push("/login");
  },
  oneTimeFinish({ commit, dispatch }) {
    Object.keys(modules).forEach((moduleName) => {
      commit(`${moduleName}/RESET`);
    });
    window.localStorage.removeItem("dojosei");
    dispatch("loading", false);
    router.push("/login");
  },

  changeTab({ commit }, payload) {
    commit("setCurrentTab", payload);
  },
  loading({ commit }, payload) {
    commit("setLoading", payload);
  },
  signOutAction() {},
};

export default actions;
