/* import state from "./state.js";
 */
import initialState from "./state";

const mutations = {
  setAuth: (state, payload) => {
    state.currentAuth = payload;
  },
  /* RESET() {
    // HELPER FUNCTION?
    const newState = state.initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  }, */
  RESET: (state) => {
    Object.assign(state, initialState);
  },
};

export default mutations;
